// Generated by Framer (9f68555)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["yzW1wNmhu", "VeQSQ4PUT"];

const serializationHash = "framer-9SXsl"

const variantClassNames = {VeQSQ4PUT: "framer-v-159q512", yzW1wNmhu: "framer-v-t134mo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Horizontal: "VeQSQ4PUT", Vertical: "yzW1wNmhu"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "yzW1wNmhu"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yzW1wNmhu", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-t134mo", className, classNames)} data-framer-name={"Vertical"} layoutDependency={layoutDependency} layoutId={"yzW1wNmhu"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({VeQSQ4PUT: {"data-framer-name": "Horizontal"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 42) - 0) - 40) / 2)))), pixelHeight: 164, pixelWidth: 160, src: "https://framerusercontent.com/images/fgs2fO0j3a60aACvc1MEXuKtfFw.png"}} className={"framer-717x90"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"PNlP8iPBJ"} style={{rotate: 0}} variants={{VeQSQ4PUT: {rotate: 90}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-9SXsl.framer-hejmk4, .framer-9SXsl .framer-hejmk4 { display: block; }", ".framer-9SXsl.framer-t134mo { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 42px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 40px; }", ".framer-9SXsl .framer-717x90 { flex: none; height: 40px; overflow: visible; position: relative; width: 40px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-9SXsl.framer-t134mo { gap: 0px; } .framer-9SXsl.framer-t134mo > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-9SXsl.framer-t134mo > :first-child { margin-left: 0px; } .framer-9SXsl.framer-t134mo > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 42
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"VeQSQ4PUT":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerTf4Piv1dS: React.ComponentType<Props> = withCSS(Component, css, "framer-9SXsl") as typeof Component;
export default FramerTf4Piv1dS;

FramerTf4Piv1dS.displayName = "Helper/Patch";

FramerTf4Piv1dS.defaultProps = {height: 42, width: 40};

addPropertyControls(FramerTf4Piv1dS, {variant: {options: ["yzW1wNmhu", "VeQSQ4PUT"], optionTitles: ["Vertical", "Horizontal"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerTf4Piv1dS, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})